import Swal from "sweetalert2";

export function showSuccessAlert(title, message, timerDuration) {
  let timerInterval;
  Swal.fire({
    position: "top",
    title: title,
    html: message,
    timer: timerDuration,
    timerProgressBar: true,
    didRender: () => {
      const timer = Swal.getPopup().querySelector("b");
      timerInterval = setInterval(() => {
        if (timer) {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    }
  }).then((result) => {
    if (result.dismiss === Swal.DismissReason.timer) {
      
    }
  });
}

export function showFailed(title, text){
    Swal.fire({
        icon: "error",
        title: title,
        text: text,
      });
}

export function showWarning(title, text){
  Swal.fire({
      icon: "warning",
      title: title,
      text: text,
    });
}