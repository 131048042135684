import React, { useEffect, useState } from "react";
import { usePagination, useTable } from "react-table";
import { Link } from "react-router-dom";
import cookies from "../../../../CommonFunction/cookies";
import { FaSpinner } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import DataTable from "../Elements/DataTable";
import Pagination from "../Elements/Pagination";
import EmptyDataAlert from "../Elements/EmptyDataAlert";
import Loader from "../../../../CommonFunction/Loader";
import { fetchOutboxList } from "../../../../API/Bank/Outbox";

const BankOutbox = () => {
  const jwtToken = cookies.get("jwt");
  const [loading, setLoading] = useState(true);
  const [loanData, setLoanData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchOutboxList(jwtToken, paginationData.page, searchQuery);
      if (data?.code === 400) {
        setLoanData([]);
      } else {
        setLoanData(data.docs);
        setPaginationData({
          totalDocs: data.totalDocs,
          totalPages: data.totalPages,
          page: data.page,
          limit: data.limit,
          hasNextPage: data.hasNextPage,
          hasPrevPage: data.hasPrevPage,
          nextPage: data.nextPage,
          prevPage: data.prevPage,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    fetchData()
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Received On",
        accessor: "notices.created_at",
        Cell: ({ value }) => (
          <span className="flex justify-center">{new Date(value).toLocaleDateString()}</span>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value }) => (
          <div className="text-center border-bgclr2">
            <div className=" border-bgclr2 mx-4">
              {value ? value : "Null"}
            </div>
          </div>
        ),
      },
      {
        Header: "UCID",
        accessor: "ucid",
        Cell: ({ value }) => (
          <div className="text-center border-bgclr2">
            <div className=" border-bgclr2 mx-4">
              {value ? value : "Null"}
            </div>
          </div>
        ),
      },
      {
        Header: "Subject",
        accessor: "notices.subject",  // Correct path for subject
        Cell: ({ value }) => <div className="text-center">{value || "Null"}</div>,
      },
      {
        Header: "Linked Loan Number",
        accessor: "notices.loan_id",
        Cell: ({ value }) => <div className="text-center">{value || "Null"}</div>,
      },
      {
        Header: "Download Link",
        accessor: "notices.download_link",  // Correct path for download link
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer" className="bg-btnclr px-2 py-2 text-sm flex justify-center rounded-md shadow-lg hover:bg-btnhover text-txtclr">
            Download
          </a>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: loanData,
      initialState: { pageIndex: 0 },
      pageCount: paginationData.totalPages,
      manualPagination: true,
    },
    usePagination
  );

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1;
    if (nextPageIndex < paginationData.totalPages) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: nextPageIndex + 1,
      }));
    }
  };

  const previousPage = () => {
    const prevPageIndex = paginationData.page - 1;
    if (prevPageIndex >= 1) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: prevPageIndex,
      }));
    }
  };

  return (
    <>
      <div className="root-style">
        <div>
          <div className="searchBar-style">
            <input
              type="search"
              placeholder="Search"
              className="searchBar-input"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button onClick={() => handleSearch()} className="searchBar-btn">
              Search
            </button>
          </div>
          {loading ? (
            <div className="flex ml-[450px] h-screen">
              <Loader />
            </div>
          ) : (
            <>
              {loanData.length === 0 ? (
                <EmptyDataAlert title="You have no outgoing messages yet." />
              ) : (
                <div className="w-[975px] mt-6">
                  <div className="pt-3 overflow-x-auto">
                    <DataTable
                      getTableProps={getTableProps}
                      headerGroups={headerGroups}
                      getTableBodyProps={getTableBodyProps}
                      page={page}
                      prepareRow={prepareRow}
                    />
                  </div>
                  <Pagination
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    paginationData={paginationData}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BankOutbox;
