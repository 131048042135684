import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkedLoanDetails from './LinkedLoanDetails';
import Widget from '../../Pages/Widget';
import cookies from '../../../../../CommonFunction/cookies';
import { fetchIndividualData, fetchOrganizationData } from '../../../../../API/ClevDocContact';
import Loader from '../../../../../CommonFunction/Loader';
import { showWarning } from '../../../../../CommonFunction/SweetAlert';
import { decodeJwtToken } from '../../../../../CommonFunction/TokenDecode';
import { FaSpinner } from 'react-icons/fa';

const BorrowerLinkedLoan = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loanData, setLoanData] = useState([]);
    const [selectedLoanId, setSelectedLoanId] = useState(null);
    const [loanRole, setLoanRole] = useState(null);
    const [linkedLoansError, setLinkedLoansError] = useState({});
    const jwtToken = cookies.get('jwt');
    const [userRole, setUserRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const fetchLinkedLoans = async () => {
        try {
            setIsLoading(true);
            setLinkedLoansError('');
            const response = await fetch(
                `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/user/linked_loans`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${jwtToken}`,
                    },
                }
            );
            const result = await response.json();
            
            
            if (response.ok) {
                setIsLoading(false);
                setLoanData(result);
                
            } else {
                setIsLoading(false);
                setLinkedLoansError(result);
                
                throw new Error(`Failed to fetch user data: ${response.statusText}`);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error retrieving user data:', error);
        }
    };

    useEffect(() => {
        const data = decodeJwtToken(jwtToken);
        
        setUserRole(data.role); // Update user role state
    }, [jwtToken]);

    useEffect(() => {
        const fetchDataAndUpdateUserData = async () => {
            try {
                const data = await fetchIndividualData(jwtToken);
                setUserData(data);
                
                if (data && data?.step === 1) {
                    
                    showWarning('Profile Incomplete...', 'Your Profile is not completed, please complete your profile first');
                    navigate('/banking/borrowerDashboard/1');
                } else if (data && data?.step === 2 && !userData.payment) {
                    
                    showWarning('Payment Incomplete...', 'Your Payment is not completed, please complete your Payment first');
                    navigate('/banking/borrowerDashboard/purchaseSummary');
                } else {
                    await fetchLinkedLoans()
                }
            } catch (error) {
                console.error('Error retrieving user data:', error);
            }
        };

        const fetchOrgDataAndUpdate = async () => {
            try {
                const data = await fetchOrganizationData(jwtToken);
                setUserData(data);
                
                if (data && data?.step === 1) {
                    
                    showWarning('Profile Incomplete...', 'Your Profile is not completed, please complete your profile first');
                    navigate('/banking/borrowerDashboard/2');
                } else if (data && data?.step === 2) {
                    
                    showWarning('Payment Incomplete...', 'Your Payment is not completed, please complete your Payment first');
                    navigate('/banking/borrowerDashboard/purchaseSummary');
                } else {
                    await fetchLinkedLoans()
                }
            } catch (error) {
                console.error('Error retrieving organization data:', error);
            }
        };

        if (userRole === 'entity') {
            fetchOrgDataAndUpdate();
        } else if (userRole === 'user') {
            fetchDataAndUpdateUserData();
        }
    }, [jwtToken, userRole]);

    useEffect(() => {
        // Update loan role state when userData and loanData are available
        if (userData && loanData.length > 0) {
            const loan = loanData.find(loan => loan.positions.find(position => position.user === userData._id || position.organization === userData._id));
            if (loan) {
                const role = loan.positions.find(position => position.user === userData._id || position.organization === userData._id)?.role;
                setLoanRole(role);
            }
        }
    }, [userData, loanData]);

    if (userData === null || loanData === null) {
        return <Loader />
    }

    const handleButtonClick = (loanId) => {
        setSelectedLoanId(loanId);
        setShowDetails(true);
    }

    const handleCloseDetail = () => {
        setSelectedLoanId(null);
        setShowDetails(false);
    }

    return (
        <>
            <div className='bg-gray-200'>
                <div className='container mx-auto'>
                    <div className='flex lg:mx-10 pt-6 pb-10 gap-5'>
                        <div class=" hidden md:block">
                            <Widget name={userData?.first_name} UCI={userData?.system_id} />
                        </div>
                        <div className='mt-5 w-full'>
                            {!showDetails ? (
                                <div>
                                    <div className='ml-16'>
                                        <h1 className='text-2xl font-semibold text-bgclr'>Linked Loans</h1>
                                    </div>
                                    <div className='flex items-center justify-center mt-5'>
                                        <div className='grid lg:grid-cols-2 gap-5'>
                                            {loanData && loanData?.map((loan, index) => (
                                                <div className='px-6 py-3 w-[320px] bg-bgclr9 rounded-md border border-gray-400'>
                                                    <div className='flex'>
                                                        <div className='w-[60%] text-[16px] text-gray-500 text-start'>
                                                            <h1>Loan Number: </h1>
                                                            <h1>Bank Name: </h1>
                                                            <h1>Role: </h1>
                                                            <h1>Amount: </h1>
                                                        </div>
                                                        <div className='text-start'>
                                                            <h1>{loan.identity_number}</h1>
                                                            <h1>{loan.bank.name}</h1>
                                                            <h1>{loanRole}</h1>
                                                            <h1>{loan.amount} /-</h1>
                                                        </div>
                                                    </div>
                                                    <button key={index} className='mt-5 bg-bgclr w-full hover:bg-bg-btnhover text-txtclr px-4 py-2 rounded' onClick={() => handleButtonClick(loan._id)}>
                                                        View Details
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        {isLoading && (
                                            <div className="flex justify-center items-center mt-44">
                                                <FaSpinner className="animate-spin text-3xl text-gray-600" size={50} />
                                            </div>
                                        )}
                                        {loanData && (
                                            <div className='flex justify-center mt-44'>
                                                <h1 className='text-center text-3xl font-bold text-gray-400'>
                                                    {linkedLoansError && linkedLoansError.error && linkedLoansError.error[0]}
                                                </h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <LinkedLoanDetails onClose={handleCloseDetail} loanId={selectedLoanId} role={loanRole} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BorrowerLinkedLoan;
