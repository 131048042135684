export const ProceedToPay = async (jwtToken, userData) => {
    const dataToSend = {
        name: `${userData?.first_name} ${userData?.middle_name} ${userData?.last_name}` || userData?.name,
        email: userData.email,
        phone_number: userData.phone_number,
        amount: "100",
        muid: userData.system_id
    }
    try {
        if (!process.env.REACT_APP_PLATFORM_API_BASE_URL) {
            console.error('API base URL not found.');
            return;
        }
  
        const response = await fetch(
            `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/payment/pay`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(dataToSend),
            }
        );
  
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Failed to post organization data: ${response.statusText}`);
        }
    } catch (error) {
        throw new Error('Failed to load data.', error);
    }
  };
  