import React, { useContext, useEffect, useState } from 'react'
import { CiLogout } from 'react-icons/ci'
import cookies from '../../../../CommonFunction/cookies'
import { decodeJwtToken } from '../../../../CommonFunction/TokenDecode';
import { fetchBankDetails } from '../../../../API/Bank/BankDetails';
import Loader from '../../../../CommonFunction/Loader';
import { changePassword } from '../../../../API/Bank/ChnageForgotPassword';
import { UserContext } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import { showFailed, showSuccessAlert } from '../../../../CommonFunction/SweetAlert';
import { FaSpinner } from 'react-icons/fa';

const BankAccountSetting = () => {
  const jwtToken = cookies.get('jwt');
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passLoading, setPassLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [uci, setUci] = useState('');
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();

  const fetchData = async () => {
    setOldPassword('');
    setPassword('');
    setConfirmPassword('');
    try {
      const data = await fetchBankDetails(jwtToken);
      console.log("Decoded Data for Bank: ", data);
      setUserData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validatePassword = (password) => {
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return strongPasswordRegex.test(password);
  };

  const Logout = (e) => {
    e.preventDefault();
    try {
      cookies.remove('jwt', { path: '/' });
      localStorage.removeItem('user');
      dispatch({ type: "USER", payload: false });
      navigate('/login');
      setPassLoading(false);
    } catch (error) {
      setPassLoading(false);
      console.error('Error removing JWT token:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long, with at least one uppercase letter, one number, and one special character.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    if (password === oldPassword || confirmPassword === oldPassword) {
      setError('Old password and new password can not be same!');
      return;
    }
    setPassLoading(true);
    setError('');
    const formData = {
      cuid: uci,
      old_password: oldPassword,
      new_password: password,
      confirm_Password: confirmPassword
    }
    const data = await changePassword(formData, jwtToken);
    console.log("Data :--------", data);
    if (data?.code === 400) {
      setPassLoading(false);
      showFailed(`Failed`, `${data?.error[0]}`)
      setOldPassword('');
      setPassword('');
      setConfirmPassword('');
    } else {
      showSuccessAlert(`${data?.Message}`, '', 2000);
      Logout(e);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setIsLoading(true);
    const decodedData = jwtToken ? decodeJwtToken(jwtToken) : null;
    setUci(decodedData?.system_id)
    if (decodedData) {
      fetchData();
    } else {
      setUserData([]);
      setIsLoading(false);
    }
  }, [])

  return (
    <>
      <div className='root-style'>
        {!isLoading ? (
          <div className='text-gray-700 w-[975px]'>
            <div className='flex'>
              <h1 className='pl-4 pt-3 pb-2 text-2xl font-bold text-txtclr3'>Account Setting</h1>
            </div>
            <div className="flex items-center rounded-xl mb-5 pl-12 py-7 bg-txtclr">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                  {userData?.bank?.name}
                </p>
                <p className="text-sm font-normal text-gray-900 truncate dark:text-white">
                  {userData?.name}
                </p>
                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                  UCI: <span className='font-thin'>{uci}</span>
                </p>
                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                  {userData?.bank?.email}
                </p>
              </div>
            </div>
            <div onSubmit={handleSubmit} className='bg-txtclr pb-5 rounded-xl shadow-2xl'>
              <form className="md:w-full md:max-w-2xl pl-16 py-5">
                <div className=''>
                  <div className='flex'>
                    <h1 className='pt-3 pb-2 text-xl font-bold text-txtclr3'>Change Password</h1>
                  </div>
                  <div className='mb-5'>
                    <label htmlFor="opass" className="block text-sm font-medium text-txtclr2">
                      Enter Old Password<span className='text-red-600'>*</span>
                    </label>
                    <input
                      type="password"
                      placeholder='Old Password'
                      id="opass"
                      name="opass"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="input-style"
                      disabled={passLoading}
                      required
                    />
                  </div>
                  <div className="">
                    <div className='mb-4 relative'>
                      <label htmlFor="fname" className="block text-sm font-medium text-txtclr2">
                        Enter New Password<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder='New Password'
                        id="npass"
                        name="npass"
                        value={password}
                        onChange={handlePasswordChange}
                        className="input-style"
                        disabled={passLoading}
                        required
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="absolute right-3 top-8 cursor-pointer"
                      >
                        {showPassword ? "👁️" : "👁️‍🗨️"}
                      </span>
                    </div>

                    <div>
                      <label htmlFor="lname" className="block text-sm font-medium text-txtclr2">
                        Confirm New Password<span className='text-red-600'>*</span>
                      </label>
                      <input
                        type="password"
                        placeholder='Re-enter Password'
                        id="rpass"
                        name="rpass"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        className="input-style"
                        disabled={passLoading}
                        required
                      />
                    </div>
                    {error && (
                      <div className="text-red-600 mb-4">
                        {error}
                      </div>
                    )}
                    <div className="flex mt-3">
                      <button
                        disabled={passLoading}
                        type="submit"
                        className="px-4 py-2 text-md text-white bg-btnclr rounded-md hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                      >
                        {!passLoading ? 'Save Changes' : <div className='flex justify-center items-center'><FaSpinner className="animate-spin text-3xl" /></div>}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className='mt-5 bg-txtclr py-5 rounded-xl shadow-2xl'>
              <form className="md:w-full md:max-w-2xl pl-16 py-3">
                <div>
                  <div className='flex'>
                    <h1 className='pb-2 text-xl font-bold text-txtclr3'>Notification</h1>
                  </div>
                  <div>
                    <div className='flex justify-between py-3 rounded-t-lg border-b border-gray-400 pl-7 bg-gray-200'>
                      <h2 className='font-semibold text-txt-clr2'>Email : </h2>
                      <div className='pr-20'>
                        <input
                          className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-gray-50 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-300 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-bgclr2 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-bgclr2 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchChecked"
                          defaultChecked
                          disabled
                        />
                      </div>
                    </div>
                    <div className='flex justify-between py-3 border-b border-gray-400 pl-7 bg-gray-200'>
                      <h2 className='font-semibold text-txt-clr2'>WhatsApp : </h2>
                      <div className='pr-20'>
                        <input
                          className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-gray-50 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-gray-300 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-bgclr2 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-bgclr2 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchChecked"
                          defaultChecked
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className='mt-5 bg-txtclr py-5 rounded-xl shadow-2xl'>
              <button className='flex items-center text-lg pl-20 text-red-600 hover:text-red-400'>
                <CiLogout className='mt-1' />Log out
              </button>
            </div>
          </div>
        ) : (
          <div className="flex ml-[450px] h-screen">
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}

export default BankAccountSetting