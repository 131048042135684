import React, { useEffect, useMemo, useState } from "react";
import LoanDetail from "./LoanDetail";
import cookies from "../../../../../CommonFunction/cookies";
import { usePagination, useTable } from "react-table";
import DataTable from "../../Elements/DataTable";
import Pagination from "../../Elements/Pagination";
import EmptyDataAlert from "../../Elements/EmptyDataAlert";
import UserProfile from "../CustomerSearch/UserProfile";
import { fetchLoanList } from "../../../../../API/Bank/LoanSearch";
import Loader from "../../../../../CommonFunction/Loader";

const BankLoanSearch = () => {
  const jwtToken = cookies.get("jwt");
  const [loanData, setLoanData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [ucid, setUcid] = useState();
  const [showLoanDetails, setShowLoanDetails] = useState(false);
  const [loanId, setLoanId] = useState();
  const [error, setError] = useState(false);
  const [paginationData, setPaginationData] = useState({
    totalDocs: 0,
    totalPages: 1,
    page: 1,
    limit: 10,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: null,
    prevPage: null,
  });

  const fetchData = async () => {
    try {
      const data = await fetchLoanList(jwtToken, paginationData.page, searchQuery);
      if (data?.docs) {
        setLoanData(data.docs);
      } else {
        setLoanData([]);
        setError(true);
      }
      setPaginationData({
        totalDocs: data.totalDocs,
        totalPages: data.totalPages,
        page: data.page,
        limit: data.limit,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUciDetail = (uci) => {
    setUcid(uci);
    setShowLoanDetails(true);
  }

  const handleLoanDetails = (loanId) => {
    setLoanId(loanId);
    setShowLoanDetails(true);
  }

  const handleCloseDetail = () => {
    setLoanId(null);
    setUcid(null);
    setShowLoanDetails(false);
  }

  const handleSearch = async () => {
    setLoading(true);
    fetchData()
  };

  const columns = useMemo(
    () => [
      {
        Header: "Linked Loan",
        accessor: "identity_number",
        Cell: ({ row, value }) => (
          <div className="my-1 px-2">
            <button onClick={() => handleLoanDetails(row.original._id)}>
              <p>{value}</p>
            </button>
          </div>
        ),
      },
      {
        Header: "Borrower Name",
        accessor: "positions",
        Cell: ({ row }) => (
          <div className="text-center border-bgclr2 p-3 w-48">
            {row?.original?.positions?.map((position, index) => {
              // Determine if this is a user or an organization based on available properties
              const entity = position?.user
                ? position?.user
                : position?.organization;
              const name = entity?.first_name
                ? `${entity?.first_name} ${entity?.last_name}`
                : entity?.name;
              return (
                <div key={index} style={{ height: "40px" }}>
                  <div>
                    <div className="p-[2px]">
                      {name
                        ? name.charAt(0).toUpperCase() + name.slice(1)
                        : "Null"}{" "}
                      <sup
                        data-tooltip-target="tooltip-right"
                        data-tooltip-placement="right"
                        className={`border-[1.5px] rounded-md px-1.5 py-0.5 text-[15px] ml-2 mt-1 font-semibold ${position.role
                          ? position.role === "borrower"
                            ? "border-red-700 text-red-600"
                            : position.role === "mortgagor"
                              ? "border-blue-700 text-blue-800"
                              : position.role === "guarantor"
                                ? "border-green-700 text-green-800"
                                : "border-gray-700 text-gray-800"
                          : ""
                          }`}
                      >
                        {/* <Tooltip message={position?.role}> */}
                        {position.role ? position.role.charAt(0).toUpperCase() : ""}
                        {/* </Tooltip> */}
                      </sup>
                      <div id="tooltip-right" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                        {position.role}
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            }
          </div >
        ),
      },

      {
        Header: "UCI",
        accessor: "system_id", // Change accessor to a unique value
        Cell: ({ row }) => (
          <div className="text-center">
            {row?.original?.positions?.map((position) => (
              <div className="border-bgclr2 my-4 px-2">
                <button onClick={() => handleUciDetail(position.user ? position?.user?.system_id : position?.organization?.system_id)} >
                  {position.user ? position?.user?.system_id : position?.organization?.system_id || "Null"}
                </button>
              </div>
            ))}
          </div>
        ),
      },


      {
        Header: "Loan Amount",
        accessor: "amount",
        Cell: ({ value }) => (
          <div className="text-center  border-bgclr2">
            <div className="px-2 border-bgclr2 my-3">{value}</div>
          </div>
        ),
      },
      {
        Header: "Branch",
        accessor: "branch",
        Cell: ({ value }) => (
          <div className="text-center  border-bgclr2">
            <div className="px-2 border-bgclr2 my-3">{value?.name}</div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div className="text-center  border-bgclr2">
            <div className="px-2 border-bgclr2 my-3">{value}</div>
          </div>
        ),
      },
      // {
      //   Header: "Person In Charge",
      //   accessor: "person_in_charge?.name",
      //   Cell: ({ value }) => (
      //     <div className="text-center  border-bgclr2">
      //       <div className="px-2 border-bgclr2 my-3">
      //         {value ? value.charAt(0).toUpperCase() + value.slice(1) : "Null"}
      //       </div>
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: loanData,
      initialState: { pageIndex: 0 },
      pageCount: paginationData.totalPages,
      manualPagination: true,
    },
    usePagination
  );

  const nextPage = () => {
    const nextPageIndex = pageIndex + 1;
    if (nextPageIndex < paginationData.totalPages) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: nextPageIndex + 1,
      }));
      // fetchData();
    }
  };

  const previousPage = () => {
    const prevPageIndex = paginationData.page - 1;
    if (prevPageIndex >= 1) {
      setPaginationData((prevState) => ({
        ...prevState,
        page: prevPageIndex,
      }));
    }
  };

  return (
    <div className="root-style">
      {!showLoanDetails ? (
        <div>
          <div className="searchBar-style">
            <input
              type="search"
              placeholder="Search by loan number"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="searchBar-input"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
            <button
              onClick={() => handleSearch()}
              className="searchBar-btn"
            >
              Search
            </button>
          </div>
          {loading ? (
            <div className="flex ml-[450px] h-screen">
              <Loader />
            </div>
          ) : (
            <>
              {loanData.length === 0 ? (
                <div className="mt-10 h-screen">
                  <EmptyDataAlert title={error ? 'No Loans Found' : 'Search Loan by Loan number'} />
                </div>
              ) : (
                <div className="w-[975px] mt-6 h-screen">
                  <div>
                    <div className="pt-3 overflow-x-auto">
                      <DataTable
                        getTableProps={getTableProps}
                        headerGroups={headerGroups}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                      />
                    </div>
                    <Pagination
                      previousPage={previousPage}
                      nextPage={nextPage}
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      paginationData={paginationData}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          {loanId && (
            <div>
              <LoanDetail onClose={handleCloseDetail} loanId={loanId} />
            </div>
          )}
          {ucid && (
            <div>
              <UserProfile onClose={handleCloseDetail} ucid={ucid} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BankLoanSearch;
