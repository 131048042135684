import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import cookies from '../../../../../CommonFunction/cookies';
import Loader from '../../../../../CommonFunction/Loader';
import { validateFormOrganization } from '../../../../../CommonFunction/FormValidation';
import OTPPopup from '../../../../../CommonFunction/OTPPopup';
import { fetchOrganizationData } from '../../../../../API/ClevDocContact';
import { HiShieldCheck } from 'react-icons/hi';
import { FaSpinner } from 'react-icons/fa';
import { capitalizeFirstLetter } from '../../Pages/Widget';
import { showFailed, showSuccessAlert } from '../../../../../CommonFunction/SweetAlert'
import { Country, State, City } from "country-state-city";
import Select from "react-select";

const OrganizationForm = () => {

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [officeAddress, setOfficeAddress] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isOTPVerificationOpen, setIsOTPVerificationOpen] = useState(false);
    const [otp, setOTP] = useState('');
    const [otpLength, setOtpLength] = useState('');
    const navigate = useNavigate();
    const [GSTResponse, setGSTResponse] = useState(null);
    const [isLoadingGST, setIsLoadingGST] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [isLoadingVerify, setIsLodingVerify] = useState(false)
    const [timer, setTimer] = useState(10); // 5 minutes in seconds
    const [showResend, setShowResend] = useState(false);
    const [gstError, setGstError] = useState("");
    const [isLoadingWhatsapp, setIsLoadingWhatsapp] = useState(false);
    const [whatsappError, setWhatsappError] = useState("");
    const [agreeContent, setAgreeContent] = useState(false);
    const [agreePrice, setAgreePrice] = useState(false);
    const [contentErr, setContentErr] = useState('')
    const [priceErr, setPriceErr] = useState('');
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState({});
    const jwtToken = cookies.get('jwt');

    const [formData, setFormData] = useState({
        type: '',
        gst_number: '',
        phone_number: userData?.phone_number,
        pancard: userData?.personal.pancard,
        officeAddress: '',
        registeredAddress: '',
        authorized_person: '',
        whatsapp: '',
        designation: '',
        alternate_email: '',
        alternate_phone_number: '',
        state_name: selectedState,
        city_name: selectedCity
    });

    useEffect(() => {
        
    }, [selectedCountry]);

    useEffect(() => {
        
    }, [selectedState]);

    useEffect(() => {
        
    }, [selectedCity]);

    const indiaOption = Country.getAllCountries().find(country => country.name === "India");

    // Organization Type options
    const options = [
        {
            label: 'Association of Persons',
            value: 'association_of_persons'
        },
        {
            label: 'Body of Individuals',
            value: 'body_of_individuals'
        },
        {
            label: 'Company',
            value: 'company'
        },
        {
            label: 'Trust',
            value: 'trust'
        },
        {
            label: 'Limited Liability Partnership',
            value: 'limited_liability_partnership'
        },
        {
            label: 'Firm',
            value: 'firm'
        },
        {
            label: 'Government',
            value: 'government'
        },
        {
            label: 'Hindu Undevided Family',
            value: 'hindu_undevided_family'
        },
        {
            label: 'Artificial Juridical Person ',
            value: 'artificial_juridical_person'
        },
        {
            label: 'Local Authority',
            value: 'local_authority'
        }

    ];


    // Get Data
    useEffect(() => {
        const fetchOrgDataAndUpdate = async () => {
            try {
                const data = await fetchOrganizationData(jwtToken);
                setUserData(data);
                
                if (data && data?.step === 2 && !data?.payment) {
                    
                    navigate('/banking/borrowerDashboard/purchaseSummary');
                } else if (data && data?.step === 3 && data?.payment) {
                    navigate('/banking/borrowerDashboard/profile');
                }
            } catch (error) {
                console.error('Error retrieving organization data:', error);
            }
        };

        fetchOrgDataAndUpdate();

    }, [setUserData, navigate]);

    // Update Data
    const updateOrganizationData = async (e) => {
        e.preventDefault();
        
        const validationErrors = validateFormOrganization(formData, userData);

        if (formData.alternate_phone_number === userData?.phone_number) {
            validationErrors.alternate_phone_number = 'Alternative mobile number and personal contact number cannot be the same';
        }
        if (formData.alternate_email === userData?.email) {
            validationErrors.alternate_email = 'Alternative email and personal email cannot be the same';
        }
        if (!userData?.verification?.gst_number) {
            setGstError('GST Number is not verified');
        }
        if (!userData?.verification?.whatsapp_number) {
            setWhatsappError('WhataApp Number is not verified');
        }
        if (!selectedState || !selectedState.name) {
            validationErrors.state_name = 'State Name is required';
        }
        if (!selectedCity || !selectedCity.name) {
            validationErrors.city_name = 'City Name is required';
        }
        if (!agreeContent) {
            setContentErr("Please agree content declaration note.");
        }
        if (!agreePrice) {
            setPriceErr("Please agree Price declaration note.");
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setIsLoadingUpdate(true);
            const updatedData = {
                type: formData.typeValue,
                phone_number: formData.phone_number || userData?.phone_number,
                personal: {
                    gst_number: formData.gst_number || userData?.personal?.gst_number,
                    pancard: formData.pancard || userData?.personal.pancard,
                },
                address: {
                    country: "India",
                    state: selectedState.name,
                    city: selectedCity.name,
                    office_address: formData.officeAddress,
                    registered_address: formData.registeredAddress,
                },
                authorized_person: formData.authorized_person,
                designation: formData.designation,
                alternate_contacts: {
                    email: formData.alternate_email,
                    phone_number: formData.alternate_phone_number,
                },

                // formData
            };

            // Make the API call
            try {
                const response = await fetch(`${process.env.REACT_APP_PLATFORM_API_BASE_URL}/organization`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(updatedData),
                });
                
                const result = await response.json();

                if (response.ok) {
                    setIsLoadingUpdate(false);
                    
                    navigate('/banking/borrowerDashboard/purchaseSummary', { state: { formData } })
                } else {
                    setIsLoadingUpdate(false);
                    showFailed('Failed.', `${result.error}`);
                    console.error('Failed to update organization data');
                }
            } catch (error) {
                showFailed('Failed.', `${error}`);
                setIsLoadingUpdate(false);
                console.error('Error occurred while updating organization data:', error);
            }
        }
    };

    // Documents Verification
    const VerifyGST = async (e, gstNumber) => {
        e.preventDefault();
        setGstError('');
        if (!gstNumber) {
            setGstError('GST Number is required');
        } else if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/.test(gstNumber)) {
            setGstError('Invalid GST number');
        } else {
            setIsLoadingGST(true);
            try {
                const baseURL = process.env.REACT_APP_PLATFORM_API_BASE_URL;
                const dataToSend = {
                    type: 'gst',
                    value: formData.gst_number,
                };
                const response = await fetch(`${baseURL}/organization/documents/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(dataToSend),
                });

                const result = await response.json();
                if (response.ok) {
                    setIsLoadingGST(false);
                    setGSTResponse(result);
                    
                    if (response.status === 200) {
                        setIsLoadingGST(false);
                        showSuccessAlert('Otp Sent To Your Registered Mobile Number', '', 2000);
                        setOtpLength(4);
                        setIsOTPVerificationOpen(true);
                    }
                } else if (response.status === 400) {
                    setIsLoadingGST(false);
                    showFailed('Failed.', `${result.error}`);
                }
            } catch (error) {
                setIsLoadingGST(false);
                showFailed('Failed.', `${error}`);
            }
        }
    };

    // Whatsapp Verify
    const VerifyWhatsapp = async (e, whatsapp) => {
        e.preventDefault();
        setWhatsappError('');
        
        if (whatsapp.length < 10) {
            
            setWhatsappError('Please enter 10 digit whatsapp number');
        } else if (whatsapp.length === 10) {
            
            setIsLoadingWhatsapp(true);
            try {
                const baseURL = process.env.REACT_APP_PLATFORM_API_BASE_URL;

                const dataToSend = {
                    type: 'whatsapp',
                    value: formData.whatsapp,
                    role: "organization"
                };

                const response = await fetch(`${baseURL}/organization/documents/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify(dataToSend),
                });
                if (response.ok) {
                    setIsLoadingWhatsapp(false);
                    const verificationResult = await response.json();
                    
                    showSuccessAlert(`${verificationResult.response.data.message}`, '', 2000)
                    const pollInterval = 5000;
                    const poll = setInterval(async () => {
                        try {
                            const updatedUserData = await fetchOrganizationData(jwtToken);
                            setUserData(updatedUserData);
                            if (updatedUserData?.verification?.whatsapp_number === true) {
                                clearInterval(poll); // Stop polling once condition is met
                            }
                        } catch (error) {
                            console.error('Error fetching individual data:', error.message);
                        }
                    }, pollInterval);
                } else {
                    setIsLoadingWhatsapp(false);
                    showFailed('Failed.', `${response?.data?.message}`);
                    throw new Error(`Failed to verify WhatsApp: ${response.statusText}`);
                }
            } catch (error) {
                setIsLoadingWhatsapp(false);
                console.error('Error verifying WhatsApp:', error.message);
            }
        }
    };

    // OTP Resend
    const handleResendClick = (e) => {
        e.preventDefault();
        // Reset timer
        setTimer(60); // Reset timer to 10 seconds
        setShowResend(false);

        // Start the timer countdown again
        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(interval);
                    setShowResend(true);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        // Handle form submission after resend
        VerifyGST(e, formData.gst_number);
    };

    if (userData === null) {
        // Loading state
        return <Loader />;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    }

    // OTP verification
    const handleOTPVerification = async (e) => {
        e.preventDefault();
        setIsLodingVerify(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/organization/documents/verify-otp`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: 'Bearer ' + jwtToken,
                    },
                    body: JSON.stringify({
                        client_id: GSTResponse?.response.client_id,
                        otp: otp,
                        type: 'gst',
                    }),
                }
            );

            const result = await response.json();

            
            
            // const cookies = new Cookies();

            if (response.status === 200) {
                setIsLodingVerify(false);
                setIsOTPVerificationOpen(false);
                showSuccessAlert(`${result.message}`, '', 2000);
                const updatedUserData = await fetchOrganizationData(jwtToken);
                setUserData(updatedUserData);
                
            } else {
                setIsLodingVerify(false);
                showFailed('Failed.', `${result.error}`);
                console.error("OTP verification failed:", result.error);
            }
        } catch (error) {
            setIsLodingVerify(false);
            showFailed('Failed.', `${error}`);
            console.error("Error during OTP verification:", error);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setFilteredOptions(options);
        }
    };

    const handleEdit = () => {
        setIsOTPVerificationOpen(false);
    };

    const filterOptions = (event) => {
        const inputValue = event.target.value.toLowerCase();
        const filteredOptions = options.filter(option => option.label.toLowerCase().includes(inputValue));
        setFilteredOptions(filteredOptions);
    };

    const handleOptionClick = (option) => {
        setFormData({
            ...formData,
            type: option.label, // store the label in formData.type
            typeValue: option.value // store the value in formData.typeValue (you can use a different key)
        });
        setIsOpen(false);
    };

    const sameAddress = () => {
        const newOfficeAddress = formData.officeAddress;
        setOfficeAddress(newOfficeAddress);

        // Also update registeredAddress to be the same
        setFormData(prevFormData => ({
            ...prevFormData,
            registeredAddress: newOfficeAddress
        }));
    }


    return (
        <>
            <div className=" bg-gray-200 md:p-4">
                <div className='container mx-auto'>
                    <form onSubmit={updateOrganizationData} className="lg:mx-44 my-10 border-2 bg-white rounded-md border-gray-800 md:p-8 p-5">
                        <div className="grid md:gap-4">
                            <div className='flex justify-between'>
                                <div>
                                    <h1 className='font-bold text-xl text-gray-500'>Organization Details</h1>
                                </div>

                                <div>
                                    <span className='text-red-600 font-semibold text-md'>[* Indicates a required field]</span>
                                </div>
                            </div>

                            <div>
                                <div className="grid md:grid-cols-2 md:gap-8">
                                    {/* Entity Name */}
                                    <div className="mb-4 md:mt-10 mt-8">
                                        <label htmlFor="entityName" className="block text-sm font-medium text-txtclr2">
                                            Entity Name<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Entity Name'
                                            id="name"
                                            name="name"
                                            value={capitalizeFirstLetter(userData?.name)}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                    </div>

                                    {/* Organization Type */}
                                    <div className="mb-4 md:mt-10">
                                        <label htmlFor="type" className="block text-sm font-medium text-txtclr2">
                                            Organization Type<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="dropdown-input input-style"
                                            placeholder="Select an option"
                                            onClick={toggleDropdown}
                                            onChange={filterOptions}
                                            id="type"
                                            name="type"
                                            value={formData.type}

                                        />
                                        {isOpen && (
                                            <div className="dropdown-list bg-txtclr border border-bgclr4 pl-3 shadow-2xl absolute list-none max-h-[200px] overflow-y-auto">
                                                {filteredOptions.map((option, index) => (
                                                    <div
                                                        key={index}
                                                        className="list-item pb-0.5"
                                                        onClick={() => handleOptionClick(option)}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {errors.type && <span className='text-red-600 font-semibold text-[14px]'>{errors.type}</span>}
                                    </div>
                                </div>

                                {/* GST Number */}
                                <div className="grid md:grid-cols-2 md:gap-10">
                                    <div className='mb-2'>
                                        <div className='md:grid flex md:grid-cols-5 md:gap-3'>
                                            <div className="col-span-4 w-full">
                                                <label htmlFor="gstNumber" className="block text-sm font-medium text-txtclr2">
                                                    GST Number<span className='text-red-600'>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder='GST Number'
                                                    id="gst_number"
                                                    name="gst_number"
                                                    maxLength={15}
                                                    value={(userData && !userData?.personal?.gst_number) ? formData.gst_number : userData?.personal?.gst_number}
                                                    onChange={handleChange}
                                                    className={`input-style ${userData?.personal?.gst_number ? 'bg-gray-200 text-gray-500' : ''}`}
                                                    disabled={userData?.personal?.gst_number ? true : false}
                                                />
                                            </div>

                                            <div className='flex items-center mt-6 ml-2'>
                                                {!userData?.verification?.gst_number && (
                                                    <button onClick={(e) => VerifyGST(e, formData.gst_number)} disabled={isLoadingGST} className='underline px-3 h-10 rounded border font-semibold text-green-600 focus:border-green-600 ring-1 ring-green-700'>
                                                        {isLoadingGST ? <FaSpinner className="animate-spin text-3xl" /> : 'Verify'}
                                                    </button>
                                                )}
                                                {(userData && userData?.verification?.gst_number) && (
                                                    <div className='md:-mt-1 -ml-10 md:-ml-3 text-green-700'>
                                                        <HiShieldCheck size={30} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {gstError && <span className='text-red-600 font-semibold text-[14px]'>{gstError}</span>}
                                    </div>

                                    {/* Email */}
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block text-sm font-medium text-txtclr2">
                                            Email Address<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            placeholder='Enter Email Address'
                                            id="email"
                                            name="email"
                                            value={userData.email}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                    </div>
                                </div>

                                {/* WhatsApp Number */}
                                <div className='mb-4'>
                                    <div className="md:grid flex md:grid-cols-9 gap-2">
                                        {/* Whatsapp */}
                                        <div className="col-span-8 w-full">
                                            <label htmlFor="whatsapp" className="block text-sm font-medium text-txtclr2">
                                                WhatsApp<span className='text-red-600'>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                maxLength='10'
                                                placeholder='WhatsApp number'
                                                id="whatsapp"
                                                name="whatsapp"
                                                value={userData?.verification?.whatsapp_number ? userData?.personal?.whatsapp : formData.whatsapp}
                                                onChange={handleChange}
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                }}
                                                disabled={userData?.verification?.whatsapp_number ? true : false}
                                                className={`input-style ${userData?.personal?.whatsapp && userData?.verification?.whatsapp_number ? 'bg-gray-200 text-gray-500' : ''}`}
                                            />
                                        </div>

                                        <div className='flex items-center ml-2 mt-6'>
                                            {!userData?.verification?.whatsapp_number && (
                                                <button onClick={(e) => VerifyWhatsapp(e, formData.whatsapp)} disabled={isLoadingWhatsapp} className='underline px-4 h-10 rounded border font-semibold text-green-600 focus:border-green-600 ring-1 ring-green-700'>
                                                    {isLoadingWhatsapp ? <FaSpinner className="animate-spin text-3xl" /> : `${userData?.personal?.whatsapp ? 'Resend' : 'Verify'}`}
                                                </button>
                                            )}
                                            {userData?.verification?.whatsapp_number && (
                                                <div className='md:-mt-1 ml-1 text-green-700'>
                                                    <HiShieldCheck size={30} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {whatsappError && <span className='text-red-600 font-semibold text-[14px]'>{whatsappError}</span>}
                                </div>

                                <div className="grid md:grid-cols-2 md:gap-10 md:mt-2">
                                    {/* Mobile Number */}
                                    <div className="mb-4">
                                        <label htmlFor="phone_number" className="block text-sm font-medium text-txtclr2">
                                            Mobile Number  <span className='text-red-600 font-bold text-xs'>[*GST Linked]</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Mobile Number'
                                            id="phone_number"
                                            name="phone_number"
                                            value={userData?.phone_number}
                                            onChange={handleChange}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            }}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                        {errors.phone_number && <span className='text-red-600 font-semibold text-[14px]'>{errors.phone_number}</span>}
                                    </div>

                                    <div className='mb-4'>
                                        <label htmlFor="pancard" className="block text-sm font-medium text-txtclr2">
                                            PAN Number<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='PAN number'
                                            id="pancard"
                                            name="pancard"
                                            value={userData?.personal.pancard}
                                            onChange={handleChange}
                                            className="input-style bg-gray-200 text-gray-500"
                                            disabled
                                        />
                                        {errors.pancard && <span className='text-red-600 font-semibold text-[14px]'>{errors.pancard}</span>}
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-2 md:gap-10">
                                    <div className="mb-4 w-full">
                                        <label htmlFor="authorized_person" className="block text-sm font-medium text-txtclr2">
                                            Authorized Person Name<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Authorized Person Name'
                                            id="authorized_person"
                                            name="authorized_person"
                                            value={formData.authorized_person}
                                            onChange={handleChange}
                                            className="input-style"
                                        />
                                        {errors.authorized_person && <span className='text-red-600 font-semibold text-[14px]'>{errors.authorized_person}</span>}
                                    </div>

                                    {/* Email */}
                                    <div className="mb-4">
                                        <label htmlFor="designation" className="block text-sm font-medium text-txtclr2">
                                            Designation<span className='text-red-600'>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder='Enter authorized person designation......'
                                            id="designation"
                                            name="designation"
                                            value={formData.designation}
                                            onChange={handleChange}
                                            className="input-style"
                                        />
                                        {errors.designation && <span className='text-red-600 font-semibold text-[14px]'>{errors.designation}</span>}
                                    </div>
                                </div>

                                <div className="md:grid md:grid-cols-3 md:gap-8">

                                    <div className="mt-2" disabled>
                                        <label htmlFor="country_name" className="block text-sm font-medium text-txtclr2">
                                            Country<span className='text-red-600'>*</span>
                                        </label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                }),
                                            }}
                                            name='country_name'
                                            id='country_name'
                                            options={[{ ...indiaOption, isDisabled: true }]} // Disable India
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.name}
                                            value={selectedCountry || indiaOption}
                                            onChange={(item) => setSelectedCountry(item)}
                                        />
                                        {errors.country_name && <span className='text-red-600 font-semibold text-[14px]'>{errors.country_name}</span>}
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="state" className="block text-sm font-medium text-txtclr2">
                                            State<span className='text-red-600'>*</span>
                                        </label>
                                        <Select
                                            name='state_name'
                                            id='state_name'
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                }),
                                            }}
                                            options={State?.getStatesOfCountry('IN')}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedState}
                                            onChange={(item) => {
                                                setSelectedState(item);
                                            }}
                                        />
                                        {errors.state_name && <span className='text-red-600 font-semibold text-[14px]'>{errors.state_name}</span>}
                                    </div>

                                    <div className="mt-2">
                                        <label htmlFor="state" className="block text-sm font-medium text-txtclr2">
                                            City<span className='text-red-600'>*</span>
                                        </label>
                                        <Select
                                            name='city_name'
                                            id='city_name'
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: state.isFocused ? '#3b82f6' : '#0B1E59',
                                                }),
                                            }}
                                            options={City.getCitiesOfState(
                                                selectedState?.countryCode,
                                                selectedState?.isoCode
                                            )}
                                            getOptionLabel={(options) => {
                                                return options["name"];
                                            }}
                                            getOptionValue={(options) => {
                                                return options["name"];
                                            }}
                                            value={selectedCity}
                                            onChange={(item) => {
                                                setSelectedCity(item);
                                            }}
                                        />
                                        {errors.city_name && <span className='text-red-600 font-semibold text-[14px]'>{errors.city_name}</span>}
                                    </div>

                                </div>

                            </div>

                            <div className="grid md:grid-cols-9 mt-3 md:mt-0 md:gap-10">
                                {/* Current Address */}
                                <div className="col-span-4 mb-1">
                                    <label htmlFor="office_address" className="block text-sm font-medium text-txtclr2">
                                        Current Office Address<span className='text-red-600'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        placeholder='Enter Current Office Address here....'
                                        id="officeAddress"
                                        name="officeAddress"
                                        value={formData.officeAddress}
                                        onChange={handleChange}
                                        className="input-style"
                                    />
                                    {errors.officeAddress && <span className='text-red-600 font-semibold text-[14px]'>{errors.officeAddress}</span>}

                                </div>

                                <div className='flex md:justify-center col-span-1 md:mb-0 mb-3'>
                                    <input type="button" className='px-3 h-9 font-semibold rounded-md my-auto text-txtclr text-center bg-bgclr2' onClick={sameAddress} value='Same' />
                                </div>

                                <div className="col-span-4 mb-1">
                                    <label htmlFor="registered_address" className="block text-sm font-medium text-txtclr2">
                                        Registered Office Address<span className='text-red-600'>*</span>
                                    </label>
                                    <textarea
                                        type="text"
                                        placeholder='Enter Permanent Address here....'
                                        id="registered_address"
                                        name="registeredAddress"
                                        value={officeAddress || formData.registeredAddress}
                                        onChange={handleChange}
                                        className="input-style"
                                    />
                                    {errors.registeredAddress && <span className='text-red-600 font-semibold text-[14px]'>{errors.registeredAddress}</span>}

                                </div>

                            </div>

                            <div className="grid md:grid-cols-2 md:gap-10 md:mt-2">
                                {/* Alternate number */}
                                <div className="mt-3 md:mt-0">
                                    <label htmlFor="alternateNumber" className="block text-sm font-medium text-txtclr2">
                                        Alternate Number<span className='text-red-600'>*</span>
                                    </label>
                                    <input
                                        type="tel"
                                        placeholder='Mobile Number'
                                        id="alternate_phone_number"
                                        name="alternate_phone_number"
                                        value={formData.alternate_phone_number}
                                        onChange={handleChange}
                                        className="input-style"
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                        maxLength={10}
                                    />
                                    {errors.alternate_phone_number && <span className='text-red-600 font-semibold text-[14px]'>{errors.alternate_phone_number}</span>}

                                </div>

                                {/* Alternate Email */}
                                <div className="mt-3 md:mt-0">
                                    <label htmlFor="alternateEmail" className="block text-sm font-medium text-txtclr2">
                                        Alternate Email<span className='text-red-600'>*</span>
                                    </label>
                                    <input
                                        type="email"
                                        placeholder='Email'
                                        id="alternate_email"
                                        name="alternate_email"
                                        value={formData.alternate_email}
                                        onChange={handleChange}
                                        className="input-style"
                                    />
                                    {errors.alternate_email && <span className='text-red-600 font-semibold text-[14px]'>{errors.alternate_email}</span>}

                                </div>
                            </div>
                        </div>

                        <div className='text-[14px]'>
                            <div className='mt-10 leading-6'>
                                <label className='flex text-txtclr2'>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={agreeContent}
                                            onChange={(e) => {
                                                setAgreeContent(e.target.checked);
                                                setContentErr('');
                                            }}
                                            className='mr-3'
                                        />
                                    </div>
                                    <div>
                                        <span className='text-red-600 font-semibold'>*</span>I/We hereby undertake to inform ClevDoc incase of change in my existing <span className='font-semibold text-black'>mobile number, whatsapp number</span> or <span className='font-semibold text-black'>email</span> address.
                                    </div>
                                </label>
                                {contentErr && <span className='text-red-600 font-semibold ml-6'>{contentErr}</span>}
                            </div>

                            <div className='mt-2 leading-6'>
                                <label className='flex text-txtclr2'>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={agreePrice}
                                            onChange={(e) => {
                                                setAgreePrice(e.target.checked);
                                                setPriceErr('');
                                            }}
                                            className='mr-3'
                                        />
                                    </div>
                                    ⁠<div>
                                        <span className='text-red-600 font-semibold'>*</span>I/We agree to pay a sum of <span className='font-semibold text-black'>Rs. 1000 (Incl. GST)</span> as one time onboarding licence fees for using the ClevDoc Banking Portal. I further agree to re-verify my Digital KYC on yearly basis (from date of registration) and also agree to KYC updation charges as will be applicable.
                                    </div>
                                </label>
                            </div>
                            {priceErr && <span className='text-red-600 font-semibold ml-6'>{priceErr}</span>}
                        </div>

                        {/* Submit Button */}
                        <div className="flex mt-4 justify-center ">
                            <button
                                type="submit"
                                disabled={isLoadingUpdate}
                                className="px-12 py-2 text-white bg-btnclr rounded-full font-bold hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200"
                            >
                                {isLoadingUpdate ? <FaSpinner className="animate-spin text-3xl" /> : 'Submit and Pay'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            {isOTPVerificationOpen && (
                <OTPPopup
                    isOpen={isOTPVerificationOpen}
                    onClose={() => setIsOTPVerificationOpen(false)}
                    otp={otp}
                    setOTP={setOTP}
                    otpLength={otpLength}
                    handleOTPVerification={handleOTPVerification}
                    timer={timer}
                    showResend={showResend}
                    handleResendClick={handleResendClick}
                    formData={formData}
                    handleEdit={handleEdit}
                    setTimer={setTimer} // Pass setTimer as a prop
                    setShowResend={setShowResend} // Pass setShowResend as a prop
                    isLoadingVerify={isLoadingVerify}
                />
            )}
        </>

    )
}

export default OrganizationForm;