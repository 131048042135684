import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Await, useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import AmountToWord, { convertToWords } from "../../../Elements/AmountToWord";
import cookies from "../../../../../../CommonFunction/cookies";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import NoticeUpdateHeader from "../../../Elements/NoticeUpdateHeader";
import { NoticeStatusUpdate, ServeNotice, UploadNotice } from "../../../../../../API/Bank/NoticeServe";
import { showFailed, showSuccessAlert, showWarning } from "../../../../../../CommonFunction/SweetAlert";
import { NoticeLoading, ServeLoading } from "../../../Elements/ServeLoading";
import { LoanByID } from "../../../../../../API/Bank/LoanSearch";
// Define styles

const PossessionNotice = ({ loanId, onCloseNotice, noticeStatus, envId }) => {
  const [loading, setLoading] = useState(false);
  const pdfRef = useRef();
  const currentDate = new Date();
  const [validationErrors, setValidationErrors] = useState({});
  const [fetchLoading, setFetchLoading] = useState(false);
  const formatedDate = format(currentDate, "MMMM dd, yyyy");
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months start at 0
    const year = date.getFullYear();

    // Format day and month to ensure they are displayed as two digits
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  const [noticeDetail, setNoticeDetail] = useState({
    borrowerName: "",
    reservePrice: "",
    authOfficerName: "",
    authOfficerDesignation: "",
    authOfficerContact: "",
    additionalText: "",
  });
  const jwtToken = cookies.get("jwt");
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoading(true);
      try {
        const data = await LoanByID(jwtToken, loanId);
        setFormData(data);
        setFetchLoading(false)
      } catch (error) {
        setFetchLoading(false)
        showFailed('Failed.', `${error.message}`);
      }
    };
    fetchData();
  }, [jwtToken]);

  const handleNoticeChange = (e) => {
    const { name, value } = e.target;
    setNoticeDetail({ ...noticeDetail, [name]: value });
  };

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const subjectText = `Possession Notice under SARFAESI Act, 2002 For immovable	property as per Rule 8(1) read with Rule 8(2)  - ${formData?.identity_number} for an Amount of Rs. ${formData?.amount} (${convertToWords(formData?.amount)})`;

  const generatePdfAndSend = async () => {
    setLoading(true);
    const content = pdfRef.current;

    try {
      const canvas = await html2canvas(content, {
        scale: 1,
        useCORS: true,
        logging: true,
        scrollY: -window.scrollY,
        width: content.offsetWidth,
        height: content.offsetHeight,
      });

      const imgData = canvas.toDataURL("image/png");

      const mmToPx = 72 / 25.4;
      const padding = 20; // mm
      const pageWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgWidth = pageWidth - 2 * padding; // Image width with padding
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Scale image height to maintain aspect ratio

      let scaledHeight = imgHeight;
      let scaledWidth = imgWidth;
      if (imgHeight + 2 * padding > pageHeight) {
        scaledHeight = pageHeight - 2 * padding;
        scaledWidth = (canvas.width * scaledHeight) / canvas.height;
      }

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidthWithPadding = pageWidth - 2 * padding;
      const imgHeightWithPadding = pageHeight - 2 * padding;
      const canvasAspectRatio = canvas.width / canvas.height;
      let effectiveImgHeight = imgWidthWithPadding / canvasAspectRatio;
      if (effectiveImgHeight > imgHeightWithPadding) {
        effectiveImgHeight = imgHeightWithPadding;
        scaledWidth = effectiveImgHeight * canvasAspectRatio;
      } else {
        scaledWidth = imgWidthWithPadding;
      }

      
      const uploadPromises = envId?.envelope?.map(async (item) => {
        const pdfCopy = new jsPDF("p", "mm", "a4");
        pdfCopy.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          scaledWidth,
          effectiveImgHeight
        );

        pdfCopy.setFontSize(10);
        const text = `Envelope ID: ${item.envelope_id}`;
        const textWidth = pdfCopy.getTextWidth(text);
        const xCoordinate = (pageWidth - textWidth) / 2;
        pdfCopy.text(text, xCoordinate, padding);

        const pdfBlob = pdfCopy.output("blob");
        const formData1 = new FormData();
        formData1.append("file", pdfBlob, `document_${item.envelope_id}.pdf`);

        const response = await UploadNotice(jwtToken, formData1, loanId);
        

        return {
          link: response[0],
          userid: item.user,
          organization_id: item.organization,
          category: item.category,
        };
      });

      const responses = await Promise.all(uploadPromises);
      const sourceArray = responses.map((response) => ({
        link: response.link,
        userid: response.userid || '',
        organization_id: response.organization_id || '',
        role: response.category,
      }));

      const noticeData = {
        source: sourceArray,
        subject: subjectText,
        loanId: loanId,
        type: "possession",
        envelope_id: envId?._id
      };

      // Serve the notices
      const serveNoticeResponse = await ServeNotice(jwtToken, noticeData);
      

      const statusPayload = {
        loanId: loanId,
        type: 'possession',
        status: 'sent',
        bank_id: formData?.bank?._id
      };

      // Update status
      const statusUpdateResponse = await NoticeStatusUpdate(jwtToken, statusPayload);
      

      navigate("/banking/bankDashboard/serviceNotice/serveNoticeSuccess");
      showSuccessAlert('', "Notices have been sent successfully.", 2000);

    } catch (error) {
      console.error("Error in generatePdfAndSend:", error);
      showFailed('Operation Failed', `${error.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const requiredFields = [
      "demandAmount",
      "possessionDate",
      "authOfficerName",
      "authOfficerDesignation",
      "authOfficerContact"
    ];
    requiredFields.forEach((field) => {
      if (!noticeDetail[field]) {
        errors[field] = true;
      }
    });
    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      setPreviewOpen(true);
      
    } else {
      showWarning('Warning...', 'Please fill the required field first.');
    }
  };

  const handleEdit = () => {
    setPreviewOpen(false);
  };

  const borrowerPosition = formData?.positions?.find(
    (position) => position.role === "borrower"
  );
  const position = formData?.positions?.find(
    (position) => position?.role === "borrower"
  );

  // Attempt to access user.phone_number first, if not available then fallback to organization.phone_number
  const phoneNumber =
    position?.user?.phone_number ?? position?.organization?.phone_number;

  if (phoneNumber) {
    
  } else {
    
  }

  return (
    <>
      {!isPreviewOpen ? (
        <div className="mb-14">
          {fetchLoading ? (
            <NoticeLoading />
          ) : (
            <div>
              <NoticeUpdateHeader onPreview={(e) => handleSubmit(e)} onClose={onCloseNotice} />
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="bg-txtclr w-[838px] mx-auto pt-40 pb-20 px-20">
                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg underline border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Demand Notice dated (
                      <span className="font-semibold text-blue-500">
                        {noticeStatus?.reminder_1?.created_at
                          ? formatDate(noticeStatus.demand.created_at)
                          : "N/A"}{" "}
                      </span>{" "}
                      bearing Reference Number -{" "}
                      <span className="text-blue-500 font-semibold"> XXXXX)</span>
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Possession Notice under SARFAESI Act, 2002 For immovable
                      property as per Rule 8(1) read with Rule 8(2) - Loan Account
                      Number – {formData.identity_number} for an Amount of Rs.{" "}
                      {formData.amount} (<AmountToWord amount={formData?.amount} />)
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        The undersigned being the Authorised Officer of{" "}
                        <span className="text-blue-500">{formData?.bank?.name},</span>{" "}
                        give notice to you as under;
                      </li>
                      <li className="pb-5">
                        As per the provisions of Section 13(12) read with Rule 3 of
                        the Security Interest (Enforcement) Rules 2002, Demand Notice
                        dated{" "}
                        <span className="text-blue-500">
                          [
                          {noticeStatus?.reminder_1?.created_at
                            ? formatDate(noticeStatus.demand.created_at)
                            : "N/A"}
                          ]{" "}
                        </span>
                        was issued under Section 13(2) of the SARFAESI Act, 2002,
                        calling upon you to repay the outstanding amount of INR{" "}
                        <input
                          type="text"
                          placeholder="Amount demanded in the Demand Notice"
                          name="demandAmount"
                          id="demandAmount"
                          value={noticeDetail.demandAmount}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, "");
                          }}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.demandAmount ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                          required
                        />{" "}
                        /- as on{" "}
                        <span className="text-blue-500">
                          {" "}
                          [
                          {noticeStatus?.reminder_1?.created_at
                            ? formatDate(noticeStatus?.demand?.created_at)
                            : "N/A"}
                          ].
                        </span>{" "}
                        Unfortunately, despite the expiration of 60 days from the date
                        of receipt of the said Demand Notice, the outstanding amount
                        remains unpaid.
                      </li>
                      <li className="pb-5">
                        Therefore, we wish to bring to your attention that the
                        Symbolic/Constructive possession of the property mentioned
                        below has been taken on{" "}
                        <input
                          type="date"
                          required
                          placeholder="DD/MM/YYYY"
                          name="possessionDate"
                          id="possessionDate"
                          value={noticeDetail.possessionDate}
                          onChange={handleNoticeChange}
                          className={`px-1 w-32 text-center border-b ${validationErrors.possessionDate ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />{" "}
                        in accordance with the powers conferred under sub-section (4)
                        of Section 13 of the Act, read with Rule 8 of the Security
                        Interest (Enforcement) Rules, 2002.
                      </li>
                      <li className="pb-5">
                        We hereby caution you and the public in general against
                        dealing with the properties mentioned above, as any
                        transactions involving these properties will be subject to the
                        charge of{" "}
                        <span className="text-blue-500">{formData?.bank?.name},</span>
                        .
                      </li>
                      <li className="pb-5">
                        Also, we wish to bring to your attention that the provisions
                        of sub-section (8) of Section 13 of the SARFAESI Act, 2002,
                        providing you with the opportunity to redeem the secured
                        assets within the stipulated time.
                      </li>
                      <div className="mt-10">
                        <textarea
                          className="border border-gray-500 p-3"
                          name="additionalText"
                          value={noticeDetail.additionalText}
                          onChange={handleNoticeChange}
                          placeholder="You can write your additional content here............"
                          id="additionalText"
                          cols="80"
                          rows="6"
                        ></textarea>
                      </div>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerName"
                        id="authOfficerName"
                        value={noticeDetail.authOfficerName}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Name]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerDesignation"
                        id="authOfficerDesignation"
                        value={noticeDetail.authOfficerDesignation}
                        onChange={handleNoticeChange}
                        placeholder="[Authorized Officer's Designation]"
                        className={`px-1 w-[45%] border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        required
                        name="authOfficerContact"
                        id="authOfficerContact"
                        value={noticeDetail.authOfficerContact}
                        onChange={handleNoticeChange}
                        placeholder="[Contact Details of the Authorized Officer]"
                        className={`px-1 w-[55%] border-b ${validationErrors.authOfficerContact ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                      />
                    </div>
                  </div>
                  <div className="bg-txtclr pt-24 pb-28">
                    <div className="flex justify-center mb-10">
                      <h2 className="text-lg underline border-gray-800 font-bold">
                        DESCRIPTION OF THE IMMOVABLE PROPERTIES
                      </h2>
                    </div>
                    <div>
                      <ul className="list-disc leading-6 pl-5 mt-8">
                        <li className="pb-5">
                          <span className="text-blue-500">
                            Details of the secured assets that are sought to be enforced
                            under the Section 13(4) of the SARFAESI Act, 2002.
                          </span>
                        </li>
                        <li className="pb-5">
                          (Details and Particulars of all the above units/flats and
                          categories have also been uploaded on the website of{" "}
                          <span className="text-blue-500">{formData?.bank?.name} </span>
                          and also pasted on the secured asset)
                        </li>
                        <li className="pb-5">
                          Place:{" "}
                          <span className="text-blue-500">
                            {formData?.bank?.address?.address}{" "}
                          </span>
                          For,{" "}
                          <span className="text-blue-500"> {formData?.bank?.name}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="font-semibold mt-20">
                      <div>
                        <input
                          type="text"
                          required
                          name="authOfficerName"
                          id="authOfficerName"
                          value={noticeDetail.authOfficerName}
                          onChange={handleNoticeChange}
                          placeholder="[Authorized Officer's Name]"
                          className={`px-1 w-[45%] border-b ${validationErrors.authOfficerName ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          required
                          name="authOfficerDesignation"
                          id="authOfficerDesignation"
                          value={noticeDetail.authOfficerDesignation}
                          onChange={handleNoticeChange}
                          placeholder="[Authorized Officer's Designation]"
                          className={`px-1 w-[45%] border-b ${validationErrors.authOfficerDesignation ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          required
                          name="authOfficerContact"
                          id="authOfficerContact"
                          value={noticeDetail.authOfficerContact}
                          onChange={handleNoticeChange}
                          placeholder="[Contact Details of the Authorized Officer]"
                          className={`px-1 w-[55%] border-b ${validationErrors.authOfficerContact ? 'border-b-2 placeholder:text-red-600 border-red-600' : 'border-gray-700'}`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-7">
                    <button
                      type="submit"
                      className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                    >
                      Preview
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="font-serif py-10">
          {loading ? (
            <ServeLoading />
          ) : (
            <div className="w-[860px] mx-auto">
              <div className="bg-txtclr py-10 pb-28 px-20">
                <div className="flex justify-end text-gray-500 mt-5 mb-3">
                  <button
                    type="submit"
                    onClick={() => {
                      handleEdit();
                    }}
                    className="text-[15px] px-2 text-txtclr bg-black font-sans rounded-sm"
                  >
                    X
                  </button>
                </div>
                <div ref={pdfRef} className="p-4">
                  <div className="mb-10">
                    <p>Date: {formatedDate}</p>
                    <p className="text-gray-700">
                      Reference Number:{" "}
                      <span className="text-gray-500 font-sans font-normal text-sm">
                        CDNC1263546798
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mb-10">
                    <h2 className="text-lg border-b border-gray-800 font-bold">
                      "WITHOUT PREJUDICE"
                    </h2>
                  </div>
                  <div className="pt-2 pb-7">
                    <h2 className="font-semibold">
                      {borrowerPosition ? (
                        borrowerPosition.user ? (
                          `Mr. ${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                        ) : (
                          borrowerPosition.organization ? (
                            `${borrowerPosition.organization.name}`
                          ) : (
                            "Borrower Name/Organization Not Available"
                          )
                        )
                      ) : (
                        "Borrower Name/Organization Not Available"
                      )}
                    </h2>
                    <h2 className="text-blue-500">{phoneNumber}</h2>
                    {borrowerPosition && borrowerPosition.role === "borrower" && (
                      <h2 className="text-blue-500">
                        {borrowerPosition.user ? (
                          ` ${borrowerPosition?.user?.address?.current_address}`
                        ) : (
                          borrowerPosition.organization ? (
                            ` ${borrowerPosition?.organization?.address?.city}`
                          ) : (
                            "Address Not Available"
                          )
                        )}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">Reference:</h2>
                    <p className="ml-20">
                      Demand Notice dated (
                      <span className="font-semibold text-blue-500">
                        {noticeStatus?.reminder_1?.created_at
                          ? formatDate(noticeStatus?.demand?.created_at)
                          : "N/A"}
                        ,{" "}
                      </span>{" "}
                      bearing Reference Number -{" "}
                      <span className="text-blue-500 font-semibold">
                        {" "}
                        XXXXX)
                      </span>
                    </p>
                  </div>
                  <div className="flex py-10">
                    <h2 className="font-bold">Subject:</h2>
                    <h2 className="font-bold ml-24">
                      Possession Notice under SARFAESI Act, 2002 For immovable
                      property as per Rule 8(1) read with Rule 8(2) - Loan
                      Account Number – {formData.identity_number} for an
                      Amount of Rs. {formData.amount} (
                      <AmountToWord amount={formData?.amount} />)
                    </h2>
                  </div>
                  <div className="flex">
                    <h2 className="font-bold">
                      Dear{" "}
                      {borrowerPosition &&
                        (borrowerPosition.user
                          ? `${capitalizeFirstLetter(
                            borrowerPosition.user.first_name || ""
                          )} ${capitalizeFirstLetter(
                            borrowerPosition.user.last_name || ""
                          )}`
                          : borrowerPosition.organization?.name)}
                      ,
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        The undersigned being the Authorised Officer of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},
                        </span>{" "}
                        give notice to you as under;
                      </li>
                      <li className="pb-5">
                        As per the provisions of Section 13(12) read with Rule
                        3 of the Security Interest (Enforcement) Rules 2002,
                        Demand Notice dated{" "}
                        <span className="text-blue-500">
                          [
                          {noticeStatus?.reminder_1?.created_at
                            ? formatDate(noticeStatus?.demand?.created_at)
                            : "N/A"}
                          ]{" "}
                        </span>
                        was issued under Section 13(2) of the SARFAESI Act,
                        2002, calling upon you to repay the outstanding amount
                        of INR{" "}
                        <span className="font-semibold font-sans">
                          {noticeDetail.demandAmount}
                        </span>{" "}
                        /-{" "}
                        <span className="font-semibold">
                          <AmountToWord amount={noticeDetail.demandAmount} />
                        </span>{" "}
                        as on{" "}
                        <span className="text-blue-500">
                          {" "}
                          [
                          {noticeStatus?.reminder_1?.created_at
                            ? formatDate(noticeStatus?.demand?.created_at)
                            : "N/A"}
                          ].
                        </span>{" "}
                        Unfortunately, despite the expiration of 60 days from
                        the date of receipt of the said Demand Notice, the
                        outstanding amount remains unpaid.
                      </li>
                      <li className="pb-5">
                        Therefore, we wish to bring to your attention that the
                        Symbolic/Constructive possession of the property
                        mentioned below has been taken on{" "}
                        <span className="font-sans font-semibold">
                          {noticeDetail.possessionDate}
                        </span>{" "}
                        in accordance with the powers conferred under
                        sub-section (4) of Section 13 of the Act, read with
                        Rule 8 of the Security Interest (Enforcement) Rules,
                        2002.
                      </li>
                      <li className="pb-5">
                        We hereby caution you and the public in general
                        against dealing with the properties mentioned above,
                        as any transactions involving these properties will be
                        subject to the charge of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name},
                        </span>
                        .
                      </li>
                      <li className="pb-5">
                        Also, we wish to bring to your attention that the
                        provisions of sub-section (8) of Section 13 of the
                        SARFAESI Act, 2002, providing you with the opportunity
                        to redeem the secured assets within the stipulated
                        time.
                      </li>
                      <li className="pb-5">{noticeDetail.additionalText}</li>
                    </ul>
                  </div>
                  <div className="mt-24">
                    <h2>
                      For,{" "}
                      <span className="text-blue-500 font-semibold">
                        {formData?.bank?.name}
                      </span>
                    </h2>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerContact}
                      </h2>
                    </div>
                  </div>
                  <div className="flex justify-center mb-10 mt-52">
                    <h2 className="text-lg underline border-gray-800 font-bold">
                      DESCRIPTION OF THE IMMOVABLE PROPERTIES
                    </h2>
                  </div>
                  <div>
                    <ul className="list-disc leading-6 pl-5 mt-8">
                      <li className="pb-5">
                        <span className="text-blue-500">
                          Details of the secured assets that are sought to be
                          enforced under the Section 13(4) of the SARFAESI
                          Act, 2002.
                        </span>
                      </li>
                      <li className="pb-5">
                        (Details and Particulars of all the above units/flats
                        and categories have also been uploaded on the website
                        of{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.name}{" "}
                        </span>
                        and also pasted on the secured asset)
                      </li>
                      <li className="pb-5">
                        Place:{" "}
                        <span className="text-blue-500">
                          {formData?.bank?.address?.address}{" "}
                        </span>
                        For,{" "}
                        <span className="text-blue-500">
                          {" "}
                          {formData?.bank?.name}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="font-semibold mt-20">
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerName}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerDesignation}
                      </h2>
                    </div>
                    <div>
                      <h2 className="font-semibold">
                        {noticeDetail.authOfficerContact}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-16">
                  <button
                    type="submit"
                    onClick={generatePdfAndSend}
                    className="text-center bg-[#307DFF] hover:bg-btnhover text-txtclr text-[18px] py-3 md:px-10 px-14 rounded-md focus:outline-none focus:shadow-outline"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PossessionNotice;
