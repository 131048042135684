import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetNewPassword } from '../../API/Bank/ChnageForgotPassword';
import { showSuccessAlert } from '../../CommonFunction/SweetAlert';
import { FaSpinner } from 'react-icons/fa';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [uci, setUci] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const cuidWithEqual = urlParams.toString(); // This gives "CUID563192="
        const cuid = cuidWithEqual.replace('=', '');
        setUci(cuid);
    },)

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const validatePassword = (password) => {
        const strongPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return strongPasswordRegex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validatePassword(password)) {
            setError('Password must be at least 8 characters long, with at least one uppercase letter, one number, and one special character.');
            return;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        setIsLoading(true);
        setError('');
        const data = await resetNewPassword(uci, password);
        showSuccessAlert(`${data?.message}`, '', 2000);
        navigate('/login');
        setIsLoading(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="container mx-auto pt-36 mb-20 font-avenir ">
                <div className="md:py-5 rounded-3xl">
                    <div className="md:grid md:grid-cols-2 py-5 rounded-3xl">
                        <div className="text-center mt-7">
                            <h1 className="text-3xl font-semibold text-bgclr">
                                Streamlined Digital Communications,
                            </h1>
                            <h1 className="text-3xl font-semibold text-bgclr">
                                Seamless Compliance
                            </h1>
                            <p className="mt-5">
                                Efficient Financial Communications, Simplified Processes
                            </p>
                            <div className="flex justify-center mt-5">
                                <img
                                    src="https://cdn.clevdoc.com/assets/Login/Forgot+password-amico+1.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="md:mx-10 mx-3 mt-10 md:mt-0">
                            <div className='mb-5 mt-8'>
                                <h1 className="text-3xl font-semibold text-center md:text-left text-bgclr">
                                    Reset
                                </h1>
                                <h1 className="text-3xl font-semibold text-center md:text-left text-bgclr">
                                    Your Password!
                                </h1>
                                <p className='text-[16px] leading-[24px] w-[70%] mt-5 text-txtclr5'>
                                    Enter the new password. The password must contain at least 8 characters, one uppercase letter, one number, and one special character.
                                </p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-5 mt-7">
                                    <div className="mb-4 relative">
                                        <label
                                            htmlFor="password"
                                            className="block text-sm font-medium text-bgclr mb-1"
                                        >
                                            New Password<span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            className="input-style"
                                            placeholder="Enter your New Password"
                                            disabled={isLoading}
                                            required
                                        />
                                        <span
                                            onClick={togglePasswordVisibility}
                                            className="absolute right-3 top-10 cursor-pointer"
                                        >
                                            {showPassword ? "👁️" : "👁️‍🗨️"}
                                        </span>
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="confirmPassword"
                                            className="block text-sm font-medium text-bgclr mb-1"
                                        >
                                            Confirm New Password<span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            className="input-style"
                                            placeholder="Enter your Confirm New Password"
                                            disabled={isLoading}
                                            required
                                        />
                                    </div>
                                    {error && (
                                        <div className="text-red-600 mb-4">
                                            {error}
                                        </div>
                                    )}
                                    <div className='flex mt-10 justify-center'>
                                        <button disabled={isLoading} type='submit' className="px-12 py-2 text-white bg-btnclr w-full rounded-md hover:bg-bgclr focus:outline-none focus:ring focus:ring-indigo-200">
                                            {!isLoading ? 'Reset Password' : <div className='flex justify-center items-center'><FaSpinner className="animate-spin text-3xl" /></div>}
                                        </button>
                                    </div>
                                    <div disabled={isLoading} className='text-center text-txtclr5 pt-5 hover:underline'>
                                        <Link to='/login'>
                                            Back to <span className='text-bgclr font-semibold'>Log in</span>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default ResetPassword;
